import React, { Component } from 'react';
import axios from 'axios';
const url = 'https://script.google.com/macros/s/AKfycbycTivAg9dkaVg-jlFUeTWixMAIs7h0GudJ0R-6uc-KzqDsGFE/exec';
class App extends Component {
  render() {
    function sendData(e){
      e.preventDefault();
      let form = document.getElementById('form');
      let formData = new FormData(form);

      axios.post(url,formData)
      .then(function(response){
        console.log(response);
      })
      .catch(function(error){
        console.log(error);
      });
      form.reset();
    }
    return (
      <div className="App">
        <form id="form">
          <input type="text" name="Activity" />
          <input type="text" name="Lift1" />
          <input type="text" name="Lift2" />
          <input type="text" name="Lift3" />
          <input type="text" name="Lift4" />
          <button onClick={sendData}>ADD</button>
        </form>
      </div>
    );
  }
}

export default App;
